// import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className='App'>
      <h1>
        Welcome to <span style={{ color: "green" }}>BhaiKaBrand.com</span>
      </h1>
      <p>
        Owned by{" "}
        <a
          href='https://www.instagram.com/programmingera.in/'
          target='_blank'
          rel='noreferrer'
          style={{ textDecoration: "none" }}
        >
          Programming Era
        </a>
      </p>
    </div>
  );
}

export default App;
